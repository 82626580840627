/** Application container - FullPageLayout **/

// Core react libs
import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';

//UI components
import {Row, Col, Container} from 'reactstrap'

//Local libs
import { NavBarHTML, Header } from './_components';

//FullPageLayout class
class FullPageLayout extends Component {


  loading = () => <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

  render() {
    const {activeLanguage, showDefaultHeader, component: Component, ...rest} = this.props;
    return (
      <Route {...rest} render={props => (
        <div className="app">
          <Suspense fallback={this.loading()}>
            <NavBarHTML />
          </Suspense>
          <Suspense fallback={this.loading()}>
            <div className="main-container">
              {showDefaultHeader &&
              <Container>
                <Row className="d-none d-sm-block">
                <Col xs="12" className="text-center">
                  <Header />
                </Col>
                </Row>
              </Container>
              }
              <Component {...props} />
            </div>
          </Suspense>
        </div>
      )} />

    );
  }
}

export default FullPageLayout;
