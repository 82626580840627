/** Application header **/
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { Nav, Navbar, NavbarBrand,  NavItem, NavLink } from 'reactstrap';

//Local libs
import { urlConfig } from '../../../_config'

//NavBar class
class NavBar extends Component {
  render() {

    // eslint-disable-next-line
    const { children,t, ...attributes } = this.props;

    return (
      <div>
        <Navbar expand="md" light className="app-header">
              <NavbarBrand href="/" >
                <img className="logo" src={`${urlConfig.LOCAL_IMAGE_PATH}/assets/Logo-SoCITY.png`} alt="Logo" />
              </NavbarBrand>
              <Nav className="ml-auto" navbar>
                <NavItem className="d-none d-list-item d-sm-none">
                  <NavLink href="https://play.google.com/store/apps/details?id=com.socity" target="_blank">
                  <i className="fa fa-android fa-1x"></i>
                  </NavLink>
                </NavItem>
                <NavItem className="d-none d-sm-block">
                  <NavLink href="https://play.google.com/store/apps/details?id=com.socity" target="_blank">
                  <i className="fa fa-android fa-1x"></i>
                  </NavLink>
                </NavItem>

                <NavItem className="d-none d-list-item d-sm-none">
                  <NavLink href="https://itunes.apple.com/us/app/socity-abidjan/id1369173826?ls=1&mt=8" target="_blank">
                    <i className="fa fa-apple fa-1x"></i>
                  </NavLink>
                </NavItem>
                <NavItem className="d-none d-sm-block">
                  <NavLink href="https://itunes.apple.com/us/app/socity-abidjan/id1369173826?ls=1&mt=8" target="_blank">
                    <i className="fa fa-apple fa-1x"></i>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink tag={Link} active to="/">{t('NAV.LIST_ITEM')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="d-inline-block mr-2" href="https://socity.ci/contact/">{t('NAV.CONTACT')}</NavLink>
                  <NavLink className="d-none d-inline-block d-sm-none mx-2" href="https://play.google.com/store/apps/details?id=com.socity" target="_blank">
                  <i className="fa fa-android fa-1x"></i>
                  </NavLink>
                  <NavLink className="d-none d-inline-block d-sm-none mx-2" href="https://itunes.apple.com/us/app/socity-abidjan/id1369173826?ls=1&mt=8" target="_blank">
                    <i className="fa fa-apple fa-1x"></i>
                  </NavLink>
                </NavItem>
              </Nav>
          </Navbar>
      </div>
    );
  }
}

NavBar = withTranslation('Layout')(NavBar)
export { NavBar };
