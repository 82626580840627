//Home page header
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class Header extends Component {

  render() {
    const { t } = this.props;
    return (
      <h1 className="mb-4">
      { t('HEADER.TITLE') } !
      </h1>
    );
  }
}

Header = withTranslation('Layout')(Header)
export { Header };
