
export function printString(str){
  if(str !== null && str !== undefined && str !== null && str !== 'NULL')
  return str;
  else
  return ''
}

export function translate(str){
  if(str !== null && str !== undefined)
  return str;
  else
  return ''
}
