/**
urlConfig: to define the url Constants
*/

export const urlConfig = {
    BASE_URL:'',
    API_URL: process.env.REACT_APP_API_URL,
    DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL,
    FAQ_URL: process.env.REACT_APP_FAQ_URL,
    CONTACT_URL: process.env.REACT_APP_CONTACT_URL,
    LOCAL_IMAGE_PATH: process.env.REACT_APP_LOCAL_IMAGE_PATH,
    REMOTE_IMAGE_PATH:'',
    MAP_URL:'https://maps.googleapis.com/maps/api/js?key=AIzaSyBmjyFpu6yOlFXUYMsYsK6NqLtobHeqQEw',
    ANDROID_URL:'https://play.google.com/store/apps/details?id=com.socity',
    IOS_URL:'https://itunes.apple.com/us/app/socity-abidjan/id1369173826?ls=1&mt=8'
};
