//Home page header
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


//Local libs
import { urlConfig } from '../../../_config'

class Footer extends Component {

  render() {
    const { t } = this.props;
    return (
      <div className="footer">
        <div className="logo">
          <a href="https://socity.ci" >
            <img className="logo" src={`${urlConfig.LOCAL_IMAGE_PATH}/assets/logo_footer.png`} alt="Logo"  height="66" />
          </a>
        </div>
        <div className="ml-auto app-link">
          {t('FOOTER.APP_LINK')}
          &nbsp;
          <a href="https://itunes.apple.com/us/app/socity-abidjan/id1369173826?ls=1&mt=8" target="_blank" rel="noopener noreferrer">
            <img className="logo" src={`${urlConfig.LOCAL_IMAGE_PATH}/assets/footer/app-store.png`} alt="Apple store" height="36" />
          </a>
          &nbsp;{t('Or')}&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.socity" target="_blank" rel="noopener noreferrer">
            <img className="logo" src={`${urlConfig.LOCAL_IMAGE_PATH}/assets/footer/google-play.png`} alt="Google play" height="36" />
          </a>
        </div>
      </div>
    );
  }
}

Footer = withTranslation('Layout')(Footer)
export { Footer };
