/** Application header **/
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

//Local libs
import { urlConfig, appConfig } from '../../../_config'

//NavBarHTML className
class NavBarHTML extends Component {
  render() {

    // eslint-disable-next-line
    const { children,t, ...attributes } = this.props;

    return (
      <div className="navbarwrapper">
          <nav className="navbar navbar-expand-md navbar-light">
              <a href="/" className="navbar-brand">
              <img height="100" className="logo header_logo header-logo" src={`${urlConfig.LOCAL_IMAGE_PATH}/assets/logo.png`} alt="Logo" />
              </a>
              <ul id="menu" className="header-nav header-nav-main nav nav-right  nav-line-bottom nav-size-large nav-spacing-large nav-uppercase">
                  <li id="menu-item-454" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item active  menu-item-454"><a href={`${urlConfig.BASE_URL}`} className="nav-top-link">{appConfig.APP_NAME}</a></li>
                  <li id="menu-item-2079" className="menu-item menu-item-type-custom menu-item-object-custom  menu-item-2079"><a target="_blank" rel="noopener noreferrer" href={`${urlConfig.DASHBOARD_URL}`}  className="nav-top-link">Se connecter</a></li>
                  <li id="menu-item-2028" className="menu-item menu-item-type-post_type menu-item-object-page  menu-item-2028"><a  href={`${urlConfig.FAQ_URL}`} className="nav-top-link">Assistance</a></li>
                  <li id="menu-item-2080" className="mail menu-item menu-item-type-post_type menu-item-object-page  menu-item-2080"><a href={`${urlConfig.CONTACT_URL}`} className="nav-top-link">Contact</a></li>
                  {/*
                    <li id="menu-item-2087" className="face menu-item menu-item-type-custom menu-item-object-custom  menu-item-2087"><a href="https://www.facebook.com/mysocity/" className="nav-top-link">Facebook</a></li>
                   */}
              </ul>
          </nav>
      </div>
    );
  }
}

NavBarHTML = withTranslation('Layout')(NavBarHTML)
export { NavBarHTML };
